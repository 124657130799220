var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "not-found" },
    [
      _c(
        _vm.versionComponent,
        {
          tag: "component",
          attrs: {
            "nav-bar": {
              color: "#3B3B4A",
              background: "#fff",
              fontFamily: "Lexend",
              fontSize: "14px",
            },
            "content-style": {
              padding: 0,
            },
          },
          scopedSlots: _vm._u(
            [
              {
                key: "navBar.left",
                fn: function () {
                  return [
                    _c("img", {
                      staticClass: "back",
                      attrs: { src: require("./img/back.svg"), alt: "" },
                    }),
                  ]
                },
                proxy: true,
              },
              {
                key: "navBar.title",
                fn: function () {
                  return [_c("span")]
                },
                proxy: true,
              },
            ],
            null,
            true
          ),
        },
        [
          _vm._v(" "),
          _vm._v(" "),
          _c("div", { staticClass: "not-found-content" }, [
            !_vm.isYqdCredMex
              ? _c("img", {
                  staticClass: "web-back",
                  attrs: { src: require("./img/back.svg"), alt: "" },
                  on: { click: _vm.handleBack },
                })
              : _vm._e(),
            _vm._v(" "),
            _c("img", {
              staticClass: "not-found-img",
              attrs: { src: require("./img/not-found.png"), alt: "" },
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "back-btn", on: { click: _vm.handleBtn } },
              [_vm._v("\n                Volver\n            ")]
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }