<!-- @Author: chengyuzhang -->
<!-- @Date: 2020-4-16 12:18:43 -->
<!-- @Last Modified by: wangwuhui -->
<!-- @Last Modified time: 2024-05-31 13:52:23 -->

<template>
    <div class="not-found">
        <component
            :is="versionComponent"
            :nav-bar="{
                color: '#3B3B4A',
                background: '#fff',
                fontFamily: 'Lexend',
                fontSize: '14px'
            }"
            :content-style="{
                padding: 0
            }"
        >
            <template #[`navBar.left`]>
                <img
                    class="back"
                    src="./img/back.svg"
                    alt=""
                >
            </template>
            <template #[`navBar.title`]>
                <span />
            </template>
            <div class="not-found-content">
                <img
                    v-if="!isYqdCredMex"
                    class="web-back"
                    src="./img/back.svg"
                    alt=""
                    @click="handleBack"
                >
                <img
                    src="./img/not-found.png"
                    class="not-found-img"
                    alt=""
                >

                <div
                    class="back-btn"
                    @click="handleBtn"
                >
                    Volver
                </div>
            </div>
        </component>
    </div>
</template>

<script>
    import {uploadWebBizEvent} from '@shared/client/util/web-biz-event-sdk';

    import WebviewInterface from 'ssr-common/util/webview/yqg-webview-interface';
    import EcLayout from 'ssr-common/vue/component/ec-layout';
    import parseUa from 'ssr-common/vue/mixin/parse-ua';
    export default {
        name: 'NotFound',

        mixins: [parseUa()],

        components: {
            EcLayout
        },

        computed: {
            versionComponent() {
                const {$route: {query: {reqSpeBar, isAppBarHid}}} = this;
                const component = this.isYqdCredMex && reqSpeBar === '1' && (isAppBarHid === '1' || isAppBarHid.toString() === '1,1') ? EcLayout : 'div';

                return component;
            }
        },

        mounted() {
            uploadWebBizEvent(`not-found${this.$route.fullPath}`);
        },

        methods: {
            handleBtn() {
                if (this.isYqdCredMex) {
                    WebviewInterface.openHomePage();

                    return;
                }

                window.location.href = '/native/home';
            },

            handleBack() {
                if (document.referrer === '') {
                    window.location.href = '/native/home';

                    return;
                }

                window.history.back();
            }
        }
    };
</script>

<style lang="scss" scoped>
.not-found {
    .back {
        width: 25px;
        height: 25px;
    }

    .not-found-content {
        max-width: 800px;
        text-align: center;
        font-size: 12px;

        .web-back {
            width: 25px;
            height: 25px;
            display: block;
            margin: 16px 0 0 16px;
        }

        .not-found-img {
            margin: 0 auto;
            width: 80%;
            max-width: 500px;
        }

        .back-btn {
            margin: 0 auto;
            width: fit-content;
            padding: 8px 20px;
            background-color: #5338ff;
            color: #fff;
            font-weight: bold;
            border-radius: 18px;
            font-size: 12px;
        }
    }
}
</style>
