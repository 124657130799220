/**
 * @Author: xiaodongyu
 * @Date: 2024-03-14 16:48:04
 * @Last Modified by: xiaodongyu
 * @Last Modified time: 2024-03-14 16:53:56
 */
import http from 'axios';
export async function uploadWebBizEvent(eventName, webBizEventPrefix = '/web-biz-event/') {
    try {
        await http.get(`${webBizEventPrefix}${eventName}`);
    }
    catch (err) {
        // ignore
    }
}
